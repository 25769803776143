export const firebaseConfig = {
  apiKey: "AIzaSyD85s_G3tIJbvi49yyYeV0JXrTtELkmaiA",
  authDomain: "mats-88622.firebaseapp.com",
  databaseURL: "https://mats-88622-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mats-88622",
  storageBucket: "mats-88622.appspot.com",
  messagingSenderId: "42490246628",
  appId: "1:42490246628:web:88bfe568b38238505d43f1",
  measurementId: "G-JZGQY5N99P"
};
